import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import LogoImage from "../components/logoimage"
import Seo from "../components/seo"
import Footer from "../components/footer"
import {Hero, Container, Heading, Navbar, Content} from 'react-bulma-components'
const BlogPage = () => {
  
  return (  
      <Layout>
          <Seo 
            title="Heat Recovery Blog" 
            description="Green Heat Systems Blog about electric heating using cryptocurrency mining rigs"
          />
          <Hero>
            <Hero.Header renderAs="header">
                <Container>
                <Navbar>
                <Navbar.Brand>
                    <Navbar.Item href='/'>
                    <LogoImage type="green"/>
                    </Navbar.Item>
                </Navbar.Brand>
                </Navbar>
                </Container>
            </Hero.Header>
            <Hero.Body>
          <Container>
          <Heading>How Mining Bitcoin Actually Helps The Environment</Heading>
          <Content>
          The topic of climate change has made bitcoin a whipping boy in the context of the potentially harmful effects of computing on the environment. It is estimated that the amount of electricity that mining pools use is greater than that of some countries, which capture the public's imagination. Globally, this is estimated at 1% of energy produced. Consensus in a decentralized bitcoin network is based on the so-called proof of work, PoW algorithm performed by a huge number of computers. Mining pool owners are motivated to have the largest share of the computing power in the network and, as a result, to use more energy. Over time, network power reaches record levels. It seems this process will intensify and may be a threat to the environment.
          
          </Content>
            <Content>
            <Link to="/blog/how-mining-bitcoin-actually-helps-the-environment">Read more how mining helps environment</Link>
            </Content>
        </Container>
        </Hero.Body>
        </Hero>

        <Footer/>
    </Layout>
  )
  
}

export default BlogPage